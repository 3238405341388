import { Button } from "@mui/material";
import { currencyFormatter } from "../../../utils/currencyFormatter";

const Item = ({ item, handleAddToCart }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        width: "100%",
        border: "1px solid lightblue",
        borderRadius: 1,
        height: "100%",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
        cursor: "pointer",
      }}
      onClick={() => handleAddToCart(item)}
    >
      {item.photo ? (
        <img
          style={{
            maxHeight: 250,
            objectFit: "cover",
          }}
          src={item.photo}
          alt={item.title}
        />
      ) : (
        <img
          style={{
            maxHeight: 250,
            objectFit: "cover",
            borderRadius: "20px 20px 0 0",
          }}
          src={"/static/img/product-placeholder-400-400.png"}
          alt={item.title}
        />
      )}
      <div
        style={{
          fontFamily: "Arial, Helvetica, sans-serif",
          padding: "1rem",
          height: "100%",
        }}
      >
        <h3 style={{ fontSize: 20 }}>{item.name}</h3>
        <h3>Retail Price: {currencyFormatter.format(item.price)}</h3>

        <p style={{ maxHeight: 200, overflow: "hidden" }}>{item.description}</p>
      </div>
      <Button
        style={{
          borderRadius: "5px 5px 5px 5px",
          margin: "0px 10px 10px 10px",
        }}
        onClick={() => {}}
        variant="contained"
        color="primary"
      >
        Add to cart
      </Button>
    </div>
  );
};

export default Item;
