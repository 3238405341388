import { Divider, useMediaQuery } from "@mui/material";
import { useTheme } from "styled-components";
import { currencyFormatter } from "../../utils/currencyFormatter";
import ShoppingCartItem from "../cart/ShoppingCartItem";

const Cart = ({ cartItems, addToCart, decrementFromCart, removeFromCart }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const containerStyle = smallScreen
    ? { padding: 20, paddingRight: 10, minWidth: 300 }
    : { padding: 20, minWidth: 600 };

  const calculateDealerTotal = (items) =>
    items.reduce(
      (acc, item) =>
        acc +
        item.quantity *
          (item.price - item.price * ((item.discount || 0) / 100)),
      0
    );

  const calculateRetailTotal = (items) =>
    items.reduce((acc, item) => acc + item.quantity * item.price, 0);

  return (
    <div style={containerStyle}>
      <h2>Your Cart</h2>
      {cartItems?.length === 0 ? <p>No items in cart.</p> : null}
      {cartItems?.map((item) => (
        <ShoppingCartItem
          key={item.name}
          item={item}
          addToCart={addToCart}
          decrementFromCart={decrementFromCart}
          removeFromCart={removeFromCart}
        />
      ))}
      <Divider sx={{ borderBottomWidth: 5 }} />
      <div style={{ alignContent: "right" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            textAlign: "right",
          }}
        >
          <h4 style={{ margin: 0, marginRight: 5 }}>Dealer Total: </h4>
          <h2 style={{ margin: 0 }}>
            {currencyFormatter.format(calculateDealerTotal(cartItems))}
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <h4 style={{ margin: 0, marginRight: 5 }}>Retail Total: </h4>
          <h2 style={{ margin: 0 }}>
            {currencyFormatter.format(calculateRetailTotal(cartItems))}
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          <h6 style={{ margin: 0, marginRight: 5 }}>Margin: </h6>
          <h4 style={{ margin: 0 }}>
            {currencyFormatter.format(
              calculateRetailTotal(cartItems) - calculateDealerTotal(cartItems)
            )}
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Cart;
