import * as React from "react";

import useAuth from "../../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isInitialized && !isAuthenticated) {
    window.location.href = "/accounts/login/";
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;
