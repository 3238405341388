import { Grid, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React from "react";

// Todo add quote backlink
const steps = [
  "Order Submitted",
  "Order Accepted",
  "Order Picked",
  "Order Shipped",
];

export function Progress(props) {
  const isCancelled = (status) => {
    return status === "Cancelled";
  };
  const isStepOnHold = (index, status) => {
    return index === 1 && status === "Hold";
  };
  const isStepModified = (index, status) => {
    return index === 1 && status === "Modified";
  };
  return (
    <React.Fragment>
      <Grid container spacing={6} mt={4} mb={8} justifyContent="center">
        <Stepper
          style={{
            display: "flex",
            flex: 1,
            maxWidth: "800px",
          }}
          activeStep={props.step}
          alternativeLabel
        >
          {steps.map((label, index) => {
            const labelProps = {};

            labelProps.optional = (
              <Typography variant="caption">
                {props.stepLabels[index] ? props.stepLabels[index] : ""}
              </Typography>
            );

            if (index === 1) {
              labelProps.optional = (
                <Typography variant="caption">
                  {props.stepLabels[index]
                    ? props.stepLabels[index]
                    : props.step > 0
                    ? "Waiting for approval"
                    : ""}
                </Typography>
              );
            }

            if (isStepOnHold(index, props.status)) {
              labelProps.optional = (
                <Typography variant="caption" color="#d32f2f">
                  {props.holdReason}
                </Typography>
              );

              labelProps.error = true;
              labelProps.alternativelabel = "On Hold";
            }
            if (isStepModified(index, props.status)) {
              labelProps.optional = (
                <Typography variant="caption" color="#d32f2f">
                  Waiting for approval
                </Typography>
              );

              labelProps.error = true;
              labelProps.alternativelabel = "Modified";
            }
            if (isCancelled(props.state)) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  Cancelled
                </Typography>
              );
              labelProps.alternativelabel = "Cancelled";
              labelProps.error = true;
            }

            return (
              <Step
                key={label}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "green", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "gray", // Just text label (COMPLETED)
                    },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "secondary.main", // circle color (ACTIVE)
                  },
                  "& MuiStepLabel-iconContainer MuiStepLabel-alternativeLabel":
                    {
                      color: "#d32f2f", // circle color (On Hold)
                    },
                  "& .MuiStepLabel-root .Mui-error": {
                    color: "#d32f2f", // circle color (On Hold)
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      color: "common.white", // Just text label (ACTIVE)
                    },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "common.white", // circle's number (ACTIVE)
                  },
                  "& .Mui-disabled .MuiStepIcon-root": {
                    color: "gray", // circle's number (ACTIVE)
                  },
                }}
              >
                <StepLabel {...labelProps}>
                  <span style={{ color: "black", fontSize: "Medium" }}>
                    {labelProps.error ? labelProps.alternativelabel : label}
                  </span>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
    </React.Fragment>
  );
}
export default Progress;
