import CloseIcon from "@mui/icons-material/Close";
import { Card, CardContent, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "styled-components";
import styled from "styled-components/macro";
import ProductList from "../../pages/RMA/products/list";
import CartItem from "./CartItem";

import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const Centered = styled.div`
  text-align: center;
`;

const Cart = ({ cartItems, setCartItems, edit }) => {
  const descriptionElementRef = React.useRef(null);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const containerStyle = smallScreen
    ? { padding: 20, paddingRight: 10, minWidth: 300 }
    : { padding: 20, minWidth: 600 };

  const [showProductSelector, setShowProductSelector] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setShowProductSelector(false);
    setOpen(false);
  };
  const handleOpen = () => {
    setShowProductSelector(true);
    setOpen(true);
  };

  function addCartItem(product) {
    let newCartItem = {
      quantity: 1,
      product: product,
      discount: 0,
      price: product?.price,
    };
    setCartItems((prevItems) => [...prevItems, newCartItem]);
    setShowProductSelector(false);
  }

  function removeCartItem(index) {
    let newCartItems = [...cartItems];
    newCartItems.splice(index, 1);

    setCartItems(newCartItems);
  }

  function setCartItemDiscount(index, discount) {
    let newCartItems = [...cartItems];
    if (discount < 0) {
      newCartItems[index].discount = 0;
    } else {
      newCartItems[index].discount = discount;
    }
    setCartItems(newCartItems);
  }

  function setCartItemQuantity(index, quantity) {
    let newCartItems = [...cartItems];
    if (quantity < 1) {
      newCartItems[index].quantity = 1;
    } else {
      newCartItems[index].quantity = quantity;
    }
    setCartItems(newCartItems);
  }

  return (
    <Card>
      <CardContent>
        <h2>Products</h2>
        {cartItems?.length === 0 ? <p>No items in cart.</p> : null}
        {cartItems?.map((item, index) => (
          <CartItem
            key={"cartitem-" + index}
            index={index}
            item={item}
            setQuantity={setCartItemQuantity}
            setDiscount={setCartItemDiscount}
            remove={removeCartItem}
            edit={edit}
          />
        ))}
        {showProductSelector ? (
          <Dialog
            style={{
              margin: 50,
            }}
            fullScreen={true}
            fullWidth={true}
            height="1000px"
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="100%"
          >
            <DialogTitle id="scroll-dialog-title">Products</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers={true} style={{ width: "100%" }}>
              <ProductList
                handleSelection={(product) => addCartItem(product)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : (
          edit && (
            <Centered>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleOpen();
                }}
              >
                Add Products
              </Button>
            </Centered>
          )
        )}
      </CardContent>
    </Card>
  );
};

export default Cart;
