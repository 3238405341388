import { AddShoppingCart, Close as CloseIcon } from "@mui/icons-material";
import {
  Badge,
  Button,
  Drawer,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../contexts/CartProvider";
import {
  addToCart,
  clearCart,
  decrementItemQuantity,
  removeFromCart,
} from "../../contexts/cartReducer";
import useAuth from "../../hooks/useAuth";
import ShoppingCart from "../cart/ShoppingCart";

export default function CartWidget() {
  const [cartOpen, setCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  //   const getTotalItems = (items) =>
  //     items.reduce((acc, item) => acc + item.amount, 0);

  //   const handleAddToCart = (clickedItem) => {
  //     setCartItems((prev) => {
  //       const isItemInCart = prev.find((item) => item.id === clickedItem.id);

  //       if (isItemInCart) {
  //         return prev.map((item) =>
  //           item.id === clickedItem.id
  //             ? { ...item, amount: item.amount + 1 }
  //             : item
  //         );
  //       }

  //       return [...prev, { ...clickedItem, amount: 1 }];
  //     });
  //   };

  //   const handleRemoveFromCart = (id) => {
  //     setCartItems((prev) =>
  //       prev.reduce((acc, item) => {
  //         if (item.id === id) {
  //           if (item.amount === 1) return acc;
  //           return [...acc, { ...item, amount: item.amount - 1 }];
  //         } else {
  //           return [...acc, item];
  //         }
  //       }, [])
  //     );
  //   };
  const navigate = useNavigate();
  const { cart, dispatch } = useContext(CartContext);

  const removeFromCartHandler = (itemToRemove) =>
    dispatch(removeFromCart(itemToRemove));
  const addToCartHandler = (product) => {
    dispatch(addToCart(product));
  };
  const decrementFromCartHandler = (product) => {
    dispatch(decrementItemQuantity(product));
  };
  const clearCartHandler = () => {
    dispatch(clearCart());
  };
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  // const cartStyle = matches ? PerfectScrollbar : Scrollbar;

  const { user } = useAuth();

  return (
    <div>
      <Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
        {smallScreen && (
          <IconButton
            className=""
            style={{ position: "absolute", top: 3, left: 10 }}
            // variant="contained"
            onClick={() => {
              setCartOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <Button
          style={{ position: "absolute", top: 30, right: 10 }}
          // variant="contained"
          onClick={() => {
            clearCartHandler();
          }}
        >
          Clear Cart
        </Button>
        <ShoppingCart
          cartItems={cart}
          addToCart={addToCartHandler}
          decrementFromCart={decrementFromCartHandler}
          removeFromCart={removeFromCartHandler}
        />

        <div
          style={{
            display: "flex",
            direction: "row",
            justifyContent: "right",
          }}
        >
          <div
            style={{
              display: "block",
              direction: "column",
              textAlign: "right",
            }}
          >
            {user?.quoting_enabled && (
              <div>
                <Button
                  style={{ marginRight: 20 }}
                  size="small"
                  disableElevation
                  variant="contained"
                  onClick={() => {
                    let cart_items = cart.map((item) => {
                      return {
                        product: {
                          id: item.id,
                          admin_thumbnail: item.admin_thumbnail,
                          name: item.name,
                          price: item.price,
                          sku: item.sku,
                          description: item.description,
                          has_serialnumber: item.has_serialnumber,
                          photo: item.photo,
                        },
                        quantity: item.quantity,
                        discount: item.discount || 0,
                      };
                    });
                    setCartOpen(false);
                    clearCartHandler();
                    navigate("/quote/create", {
                      state: { cart_items: cart_items },
                    });
                  }}
                >
                  Create Quote
                </Button>
              </div>
            )}
            <div style={{ marginRight: 20, marginBottom: 20 }}>
              <Button
                size="small"
                disableElevation
                variant="contained"
                onClick={() => {
                  let cart_items = cart.map((item) => {
                    return {
                      product: {
                        id: item.id,
                        admin_thumbnail: item.admin_thumbnail,
                        name: item.name,
                        price: item.price,
                        sku: item.sku,
                        description: item.description,
                        has_serialnumber: item.has_serialnumber,
                        photo: item.photo,
                      },
                      price: item.price,
                      quantity: item.quantity,
                      discount: item.discount || 0,
                    };
                  });
                  setCartOpen(false);
                  clearCartHandler();
                  navigate("/order/create", {
                    state: { cart_items: cart_items },
                  });
                }}
              >
                Create Order
              </Button>
            </div>
          </div>
        </div>
      </Drawer>

      <IconButton
        style={{ position: "fixed", zIndex: 100, right: 75, top: 15 }}
        onClick={() => setCartOpen(true)}
      >
        <Badge badgeContent={cart.length} color="error">
          <AddShoppingCart />
        </Badge>
      </IconButton>
    </div>
  );
}
