import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  Box,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Select,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { Camera, Image } from "react-feather";

import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const axios = require("axios").default;

const Card = styled(MuiCard)(spacing);

const Button = styled(MuiButton)(spacing);

const TextField = styled(MuiTextField)(spacing);

function PhotoList(props) {
  const [photo, setPhoto] = useState();
  useEffect(() => {
    setPhoto(props.photo);
  }, [props.photo]);

  if (photo === undefined) {
    return (
      <List dense={true}>
        <ListItem key={0}>
          <ListItemIcon>
            <Image />
          </ListItemIcon>
          <ListItemText primary="No photo" />
        </ListItem>
      </List>
    );
  } else {
    return (
      <List dense={true}>
        <ListItem key={0}>
          <ListItemIcon>
            <Image />
          </ListItemIcon>
          <ListItemText primary={photo.name} />
        </ListItem>
      </List>
    );
  }
}

function ProductAdd(props) {
  const [isLoading, setLoading] = React.useState(false);
  const [photo, setPhoto] = useState();
  const [manufacturers, setManufacturers] = useState([]);
  const [name, setName] = useState("");
  const [manufacturer, setManufacturer] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchManufacturers = axios.get("/manufacturers/");

    axios
      .all([fetchManufacturers])
      .then(
        axios.spread((...responses) => {
          const fetchedManufacturers = responses[0].data;
          setManufacturers(fetchedManufacturers);
        })
      )
      .catch((errors) => {
        console.log(errors);
      });
  }, []);

  function handleFileUpload(e) {
    let file = e.target.files[0];
    const newName = uuidv4();
    const name = newName + file.name.substring(file.name.lastIndexOf("."));
    file = new File([file], name, { type: file.type });
    setPhoto([file]);
  }

  function handleSubmit() {
    setLoading(true);

    let data = new FormData();
    data.append("name", name);
    data.append("manufacturer", "/manufacturers/" + manufacturer + "/");
    data.append("image", photo[0], photo[0].name);

    axios
      .post("/products/", data, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      })
      .then(() => {
        navigate("/products");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleChange = (event) => {
    setManufacturer(event.target.value);
  };

  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h1" gutterBottom>
          Add Product
        </Typography>
        {isLoading ? (
          <Box display="flex" justifyContent="center" my={6}>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              autoFocus
              name="name"
              id="name"
              label="Name"
              value={name}
              error={!name}
              fullWidth
              onChange={(e) => {
                setName(e.target.value);
              }}
              variant="outlined"
              my={2}
            />
            <FormControl fullWidth>
              <InputLabel id="manufacturer-label">Manufacturer</InputLabel>
              <Select
                labelId="manufacturer-label"
                id="manufacturer"
                value={manufacturer}
                label="Manufacturer"
                onChange={handleChange}
              >
                {manufacturers.map((manufacturer) => {
                  return (
                    <MenuItem key={manufacturer.pk} value={manufacturer.pk}>
                      {manufacturer.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!photo ? (
              <List dense={true}>
                <ListItem key={0}>
                  <ListItemIcon>
                    <Image />
                  </ListItemIcon>
                  <ListItemText primary="No photo" />
                </ListItem>
              </List>
            ) : (
              <List dense={true}>
                <ListItem key={0}>
                  <ListItemIcon>
                    <Image />
                  </ListItemIcon>
                  <ListItemText primary={photo[0].name} />
                </ListItem>
              </List>
            )}
            <Button
              variant="contained"
              component="label"
              disabled={!name || !manufacturer}
            >
              <Camera style={{ marginRight: "2px" }} />
              &nbsp;Add Photo
              <input
                id="file"
                accept="image/*"
                type="file"
                hidden
                multiple
                onChange={handleFileUpload}
              />
            </Button>
            <Box
              style={{
                display: "flex",
                justifyContent: "right",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!name || !manufacturer || !photo}
              >
                Save
              </Button>
            </Box>
          </form>
        )}
      </CardContent>
    </Card>
  );
}

export default ProductAdd;
