import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Fab,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext } from "react";
import { useTheme } from "styled-components";
import { CartContext } from "../../contexts/CartProvider";
import { currencyFormatter } from "../../utils/currencyFormatter";

const CartItem = ({ item, index, setQuantity, setDiscount, remove, edit }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { dispatch } = useContext(CartContext);
  const smallImage = {
    maxWidth: 75,
    objectFit: "cover",
    marginRight: 20,
    borderRadius: 20,
  };
  const largeImage = {
    maxWidth: 150,
    objectFit: "cover",
    marginRight: 20,
    borderRadius: 20,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        // borderBottom: "1px solid lightblue",
        paddingBottom: 20,
        margin: 10,
      }}
    >
      <div style={{ maxWidth: 200 }}>
        <img
          src={item?.product?.photo}
          alt={item?.product?.title}
          style={smallScreen ? smallImage : largeImage}
        />
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {smallScreen ? (
            <h4>{item?.product?.name}</h4>
          ) : (
            <h3>{item?.product?.name}</h3>
          )}
          {edit && (
            <div style={{ textAlign: "right", alignContent: "center" }}>
              <Button
                size="small"
                color="error"
                disableElevation
                onClick={() => remove(index)}
              >
                Remove
              </Button>
            </div>
          )}
        </div>
        {item.serial_numbers?.length > 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Serial Numbers
              </AccordionSummary>
              <AccordionDetails>
                {item.serial_numbers.map((sn, i) => (
                  <Typography key={i}>{sn}</Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>Price: {currencyFormatter.format(item?.price || 0)}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              maxWidth: 150,
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <label style={{ marginRight: 10 }}>Discount: </label>
            {edit ? (
              <input
                style={{ width: 50 }}
                name="discount"
                onChange={(e) => setDiscount(index, e.target.value)}
                value={item.discount}
              />
            ) : (
              <p>{item.discount}%</p>
            )}
          </div>
        </div>
        <div
          className="buttons"
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
              marginLeft: 20,
            }}
          >
            {/* <Typography style={{ marginRight: 5 }}>Total: </Typography> */}
            <Typography style={{ fontWeight: "bold" }}>
              {currencyFormatter.format(
                item.quantity *
                  (item.price - (item.price * (item.discount || 0)) / 100) || 0
              )}
            </Typography>
          </div>
          {edit ? (
            <div
              className="buttons"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flex: 1,
                maxWidth: 200,
              }}
            >
              <Fab
                size="small"
                sx={{
                  backgroundColor: "lightgrey",
                  color: "black",
                }}
                onClick={() => setQuantity(index, item.quantity - 1)}
              >
                -
              </Fab>
              <Typography
                style={{ alignContent: "center", fontColor: "black" }}
                variant="h6"
              >
                {item.quantity}
              </Typography>
              <Fab
                size="small"
                sx={{
                  backgroundColor: "lightgrey",
                  color: "black",
                }}
                onClick={() => setQuantity(index, item.quantity + 1)}
              >
                +
              </Fab>

              {/* <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={() => decrementFromCart(item)}
            >
              -
            </Button>
            <p>{item.quantity}</p>
            <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={() => addToCart(item)}
            >
              +
            </Button> */}
            </div>
          ) : (
            <Typography
              style={{ alignContent: "center", fontColor: "black" }}
              variant="h6"
            >
              Qty: {item.quantity}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
