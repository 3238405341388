import { Button, Fab, Typography, useMediaQuery } from "@mui/material";
import { useContext } from "react";
import { useTheme } from "styled-components";
import { CartContext } from "../../contexts/CartProvider";
import { setDiscount } from "../../contexts/cartReducer";
import { currencyFormatter } from "../../utils/currencyFormatter";

const CartItem = ({ item, addToCart, decrementFromCart, removeFromCart }) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { dispatch } = useContext(CartContext);
  const smallImage = {
    maxWidth: 75,
    objectFit: "cover",
    marginRight: 20,
    borderRadius: 20,
  };
  const largeImage = {
    maxWidth: 150,
    objectFit: "cover",
    marginRight: 20,
    borderRadius: 20,
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        // borderBottom: "1px solid lightblue",
        paddingBottom: 20,
        margin: 10,
      }}
    >
      <div style={{ maxWidth: 200 }}>
        <img
          src={item.photo}
          alt={item.title}
          style={smallScreen ? smallImage : largeImage}
        />
      </div>
      <div style={{ flex: 1 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {smallScreen ? <h4>{item.name}</h4> : <h3>{item.name}</h3>}
          <div style={{ textAlign: "right", alignContent: "center" }}>
            <Button
              size="small"
              color="error"
              disableElevation
              // variant="contained"
              onClick={() => removeFromCart(item)}
            >
              remove
            </Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <p>Price: {currencyFormatter.format(item.price)}</p>
          <div
            style={{
              flexDirection: "row",
              maxWidth: 150,
              alignContent: "center",
            }}
          >
            <label>Discount: </label>
            <input
              style={{ width: 50 }}
              name="discount"
              onChange={(e) => {
                dispatch(setDiscount(item, e.target.value));
              }} // ... and update the state variable on any edits!
              value={item.discount}
            />
          </div>
        </div>
        <div
          className="buttons"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div
            className="buttons"
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              maxWidth: 200,
            }}
          >
            <Fab
              size="small"
              sx={{
                backgroundColor: "lightgrey",
                color: "black",
              }}
              onClick={() => decrementFromCart(item)}
            >
              -
            </Fab>
            <Typography
              style={{ alignContent: "center", fontColor: "black" }}
              variant="h6"
            >
              {item.quantity}
            </Typography>
            <Fab
              size="small"
              sx={{
                backgroundColor: "lightgrey",
                color: "black",
              }}
              onClick={() => addToCart(item)}
            >
              +
            </Fab>

            {/* <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={() => decrementFromCart(item)}
            >
              -
            </Button>
            <p>{item.quantity}</p>
            <Button
              size="small"
              disableElevation
              variant="contained"
              onClick={() => addToCart(item)}
            >
              +
            </Button> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "center",
            }}
          >
            {/* <Typography style={{ marginRight: 5 }}>Total: </Typography> */}
            <Typography style={{ fontWeight: "bold" }}>
              {currencyFormatter.format(
                item.quantity *
                  (item.price - (item.price * (item.discount || 0)) / 100)
              )}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
