import {
  Alert,
  CardContent,
  Button as MuiButton,
  Card as MuiCard,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components/macro";

import { useNavigate } from "react-router-dom";

import { Formik } from "formik";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";

const axios = require("axios").default;

function CreateCustomer() {
  const timeOut = (time) => new Promise((res) => setTimeout(res, time));

  const Card = styled(MuiCard)(spacing);

  const Button = styled(MuiButton)(spacing);

  const TextField = styled(MuiTextField)(spacing);

  const { id } = useParams();
  const navigate = useNavigate();
  // const formRef = useRef();

  const [initialValues, setInitialValues] = useState({
    name: "",
    company_name: "",
    phone: "",
    email: "",
    shipping_contact_name: "",
    shipping_phone: "",
    shipping_street_address: "",
    shipping_street_address2: "",
    shipping_city: "",
    shipping_state: "",
    shipping_zip: "",
    billing_contact_name: "",
    billing_phone: "",
    billing_street_address: "",
    billing_street_address2: "",
    billing_city: "",
    billing_state: "",
    billing_zip: "",
  });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const resp = await axios.post("/customers/", {
        ...values,
      });

      navigate("/customers/" + resp.data.id);
    } catch (error) {
      console.log("Error!", error);

      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Card mb={6}>
      <CardContent>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h1" gutterBottom>
            Customer
          </Typography>
          <div></div>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={Yup.object().shape({
            name: Yup.string().max(250).required("Contact name is required"),
            company_name: Yup.string().required("Company name is required"),
            phone: Yup.string().max(25).required("Phone number is required"),
            email: Yup.string()
              .email("Must be a valid email")
              .required("Email is Required"),
            billing_contact_name: Yup.string().required(
              "Billing contact name is required"
            ),
            billing_phone: Yup.string()
              .max(25)
              .required("Billing phone is required"),
            billing_street_address: Yup.string().required(
              "Billing street adress is required"
            ),
            billing_city: Yup.string().required("Billing city is required"),
            billing_state: Yup.string()
              .max(2)
              .required("Billing state is required"),
            billing_zip: Yup.string()
              .max(7)
              .required("Billing zip is required"),
            shipping_contact_name: Yup.string().required(
              "Shipping contact name is required"
            ),
            shipping_phone: Yup.string()
              .max(25)
              .required("Shipping phone is required"),
            shipping_street_address: Yup.string().required(
              "Shipping street adress is required"
            ),
            shipping_city: Yup.string().required("Shipping city is required"),
            shipping_state: Yup.string()
              .max(2)
              .required("Shipping state is required"),
            shipping_zip: Yup.string()
              .max(7)
              .required("Shipping zip is required"),
          })}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            touched,
            values,
            status,
          }) => (
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Customer Details
                </Typography>
                <Typography variant="body2" gutterBottom>
                  fill in the following customer information
                </Typography>

                {status && status.sent && (
                  <Alert severity="success" my={3}>
                    New customer has been submitted successfully!
                  </Alert>
                )}
                <form id="editCustomer" onSubmit={handleSubmit}>
                  <TextField
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    id="company_name"
                    name="company_name"
                    label="Company"
                    value={values.company_name}
                    error={Boolean(touched.company_name && errors.company_name)}
                    fullWidth
                    helperText={touched.company_name && errors.company_name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <MuiPhoneNumber
                    id="phone"
                    name="phone"
                    label="Phone Number"
                    value={values.phone}
                    data-cy="number"
                    defaultCountry={"us"}
                    onChange={(e) => setFieldValue("phone", e)}
                    onBlur={handleBlur}
                    helperText={touched.phone && errors.phone}
                    error={Boolean(touched.phone && errors.phone)}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    sx={{ my: 4 }}
                  />
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type="email"
                    my={4}
                  />

                  <TextField
                    id="billing_contact_name"
                    name="billing_contact_name"
                    label="Billing Contact"
                    value={values.billing_contact_name}
                    error={Boolean(
                      touched.billing_contact_name &&
                        errors.billing_contact_name
                    )}
                    fullWidth
                    helperText={
                      touched.billing_contact_name &&
                      errors.billing_contact_name
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <MuiPhoneNumber
                    id="billing_phone"
                    name="billing_phone"
                    label="Billing Phone"
                    value={values.billing_phone}
                    data-cy="number"
                    defaultCountry={"us"}
                    onChange={handleChange("billing_phone")}
                    onBlur={handleBlur}
                    helperText={touched.billing_phone && errors.billing_phone}
                    error={Boolean(
                      touched.billing_phone && errors.billing_phone
                    )}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    sx={{ my: 4 }}
                  />
                  <TextField
                    id="billing_street_address"
                    name="billing_street_address"
                    label="Billing Steet Address"
                    value={values.billing_steet_address}
                    error={Boolean(
                      touched.billing_steet_address &&
                        errors.billing_steet_address
                    )}
                    fullWidth
                    helperText={
                      touched.billing_steet_address &&
                      errors.billing_steet_address
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="billing_street_address2"
                    label="Billing Street Address2"
                    value={values.billing_steet_address2}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    id="billing_city"
                    name="billing_city"
                    label="Billing City"
                    value={values.billing_city}
                    error={Boolean(touched.billing_city && errors.billing_city)}
                    fullWidth
                    helperText={touched.billing_city && errors.billing_city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    id="billing_state"
                    name="billing_state"
                    label="Billing State"
                    value={values.billing_state}
                    inputProps={{ maxLength: 2 }}
                    error={Boolean(
                      touched.billing_state && errors.billing_state
                    )}
                    fullWidth
                    helperText={touched.billing_state && errors.billing_state}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    id="billing_zip"
                    name="billing_zip"
                    label="Billing Zip"
                    value={values.billing_zip}
                    error={Boolean(touched.billing_zip && errors.billing_zip)}
                    fullWidth
                    helperText={touched.billing_zip && errors.billing_zip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />

                  <TextField
                    id="shipping_contact_name"
                    name="shipping_contact_name"
                    label="Shipping Contact"
                    value={values.shipping_contact_name}
                    error={Boolean(
                      touched.shipping_contact_name &&
                        errors.shipping_contact_name
                    )}
                    fullWidth
                    helperText={
                      touched.shipping_contact_name &&
                      errors.shipping_contact_name
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <MuiPhoneNumber
                    id="shipping_phone"
                    name="shipping_phone"
                    label="Shipping Phone"
                    value={values.shipping_phone}
                    data-cy="number"
                    defaultCountry={"us"}
                    onChange={handleChange("shipping_phone")}
                    onBlur={handleBlur}
                    helperText={touched.shipping_phone && errors.shipping_phone}
                    error={Boolean(
                      touched.shipping_phone && errors.shipping_phone
                    )}
                    fullWidth
                    type="tel"
                    variant="outlined"
                    sx={{ my: 4 }}
                  />
                  <TextField
                    id="shipping_street_address"
                    name="shipping_street_address"
                    label="Shipping Address"
                    value={values.shipping_street_address}
                    error={Boolean(
                      touched.shipping_street_address &&
                        errors.shipping_street_address
                    )}
                    fullWidth
                    helperText={
                      touched.shipping_street_address &&
                      errors.shipping_street_address
                    }
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    name="shipping_street_address2"
                    label="Shipping Street Address2"
                    value={values.shipping_steet_address2}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    id="shipping_city"
                    name="shipping_city"
                    label="Shipping City"
                    value={values.shipping_city}
                    error={Boolean(
                      touched.shipping_city && errors.shipping_city
                    )}
                    fullWidth
                    helperText={touched.shipping_city && errors.shipping_city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    id="shipping_state"
                    name="shipping_state"
                    label="Shipping State"
                    value={values.shipping_state}
                    error={Boolean(
                      touched.shipping_state && errors.shipping_state
                    )}
                    fullWidth
                    helperText={touched.shipping_state && errors.shipping_state}
                    inputProps={{ maxLength: 2 }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <TextField
                    id="shipping_zip"
                    name="shipping_zip"
                    label="Shipping Zip"
                    value={values.shipping_zip}
                    error={Boolean(touched.shipping_zip && errors.shipping_zip)}
                    fullWidth
                    helperText={touched.shipping_zip && errors.shipping_zip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={4}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    onClick={handleSubmit}
                  >
                    Create Customer
                  </Button>
                </form>
              </CardContent>
            </Card>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
}

export default CreateCustomer;
