import { Formik } from "formik";
import React from "react";
import styled from "styled-components/macro";

import {
  Box,
  CardContent,
  CircularProgress,
  Grid,
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const timeOut = (time) => new Promise((res) => setTimeout(res, time));

// const initialValues = {
//   firstName: "Lucy",
//   lastName: "Lavender",
//   email: "lucylavender@gmail.com",
//   password: "mypassword123",
//   confirmPassword: "mypassword123",
// };

// const validationSchema = Yup.object().shape({
//   firstName: Yup.string().required("Required"),
//   lastName: Yup.string().required("Required"),
//   email: Yup.string().email().required("Required"),
//   password: Yup.string()
//     .min(12, "Must be at least 12 characters")
//     .max(255)
//     .required("Required"),
//   confirmPassword: Yup.string().when("password", {
//     is: (val) => (val && val.length > 0 ? true : false),
//     then: Yup.string().oneOf(
//       [Yup.ref("password")],
//       "Both password need to be the same"
//     ),
//   }),
// });

function BasicForm(props) {
  // Todo make a validation generated from props
  // const validationSchema = Yup.object().shape({
  //   firstName: Yup.string().required("Required"),
  //   lastName: Yup.string().required("Required"),
  //   email: Yup.string().email().required("Required"),
  //   password: Yup.string()
  //     .min(12, "Must be at least 12 characters")
  //     .max(255)
  //     .required("Required"),
  //   confirmPassword: Yup.string().when("password", {
  //     is: (val) => (val && val.length > 0 ? true : false),
  //     then: Yup.string().oneOf(
  //       [Yup.ref("password")],
  //       "Both password need to be the same"
  //     ),
  //   }),
  // });

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      props.submit(values);
      // await timeOut(1500);
      // resetForm();
      // setStatus({ sent: true });
      // setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={props.inputs}
      // validationSchema={props.validations}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
      }) => (
        <Card mb={6}>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {props.title}
            </Typography>
            <Typography variant="body2" gutterBottom>
              {props.subtitle}
            </Typography>

            {status && status.sent && (
              <Alert severity="success" my={3}>
                Your data has been submitted successfully!
              </Alert>
            )}

            {isSubmitting ? (
              <Box display="flex" justifyContent="center" my={6}>
                <CircularProgress />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={6}>
                  <Grid item>
                    {Object.entries(props.inputs).map(([key, input]) => {
                      return (
                        <TextField
                          key={key}
                          name={key}
                          label={key}
                          value={values[key] || ""}
                          error={Boolean(touched.key && errors.key)}
                          fullWidth
                          helperText={touched.key && errors.key}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
                {/* <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button> */}
              </form>
            )}
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default BasicForm;
