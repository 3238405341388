import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

const axios = require("axios").default;

export function ContactSelector({ selectedContact, handleContactSelection }) {
  const timerRef = React.useRef();
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [inputValue, setInputValue] = React.useState(
    selectedContact?.name ? selectedContact.name : ""
  );

  useEffect(() => {
    setLoading(true);
    // setOrders([]);
    var url = "/customers/" + "?search=" + searchQuery;
    // if (props.status) {
    //   url = "/orders/?status=" + props.status;
    // }
    axios
      .get(url)
      .then((response) => {
        setSearchResults(response.data.results);
      })
      .then(() => {
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [searchQuery]);

  return (
    <Autocomplete
      id="search-bar"
      className="text"
      onInput={(e) => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          setSearchQuery(e.target.value);
        }, 300);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, contact) => {
        handleContactSelection(contact);
      }}
      value={selectedContact}
      label="Search Customers"
      fullWidth
      variant="standard"
      placeholder="Search..."
      size="small"
      inputprops={{ style: { fontSize: 18 } }} // font size of input text
      inputlabelprops={{ style: { fontSize: 18 } }}
      disablePortal
      getOptionLabel={(option) => option?.name}
      options={searchResults}
      sx={{ m: 1, width: 300, marginTop: 5 }}
      renderInput={(params) => <TextField {...params} label="Customers" />}
    />
  );
}
