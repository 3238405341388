export const getDate = (dateStr) => {
  if (dateStr === null || dateStr === undefined) return "";
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return formattedDate;
};
